<script setup lang="ts">
definePage({
	name: "AuthPage",
	redirect: "/auth/login",
});
</script>

<template lang="pug">
header-bar-top
#auth
	router-view.auth-views(v-slot="{ Component }")
		transition(name="fade", mode="out-in")
			component(:is="Component")
footer-bar
</template>

<style lang="scss" scoped>
#auth {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-top: $nav-top-bar;

	.auth-views {
		padding: 100px 5%;
		max-width: 1500px;
		align-self: center;
	}

	@include fade-transition(0.1s);
}
</style>
