<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const route = useRoute();
const store = useDefaultStore();

definePage({
	name: "LegalPage",
});

useHead({
	title: $t("app.legal.pageTitle") + " | " + store.APP_NAME,
	// title: store.APP_NAME + "- " + $t("app.legal.pageTitle"),
	meta: [
		{ name: "title", content: $t("app.legal.pageTitle") },
		{ name: "description", content: $t("app.legal.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.legal.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.legal.metaDesc"),
		},
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

function hideCard() {
	store.activeCardLegal = 0;
	store.activeCardRegular = 0;
}
</script>

<template lang="pug">
div(@click="hideCard")
	legal-hero
	legal-expertise
	map-section(:blogType="'legal'")
	legal-financing
	legal-testimonials
	legal-contact
	academy-section-new(:blogType="'legal'")
	partners-section-new
</template>

<style lang="scss" scoped></style>
