<script setup lang="ts">
definePage({
	name: "LegalPostPage",
});
</script>

<template lang="pug">
post-comp(:blogType="'legal'")
</template>

<style lang="scss" scoped>
.tags-wrapper {
	height: 100px;
	padding-top: 20px;
}

.post-wrapper {
	display: flex;
}
</style>
