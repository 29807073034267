<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import type { ICategory } from "src/components/sideBar/CategoriesBar.vue";
import type { ITag } from "src/pages/index/blog/[slug]/index.vue";
import type { TFilePickerChange } from "src/components/shared/fileUpload/FilePicker.vue";
import { $t } from "@/i18n";
import { useToastStore } from "@/store/toast";
import noImage from "@/assets/image/no-image.svg";
import { Quill } from "@vueup/vue-quill";

definePage({
	name: "CreatePost",
});

const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
const categoriesList = ref<ICategory[] | null>(null);
const tagsList = ref<ITag[] | null>(null);
const selectedCategories = ref<number[]>([]);
const selectedTags = ref<ITag[]>([]);
const catBool = ref(false);
const errorMsgBool = ref(false);
const errorMsgText = ref("");
const errorMsgTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const coverImagePreviewUrl = ref("");
const thumbImagePreviewUrl = ref("");
const buttonActive = ref(true);

useHead({
	title:
		$t("app.admin.createPost") +
		" | " +
		$t("app.adminPanel") +
		" | " +
		store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.admin.createPost") },
		{ name: "description", content: store.META_DATA.NAME_DESCRIPTION },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS_NO_INDEX },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content:
				$t("app.admin.createPost") +
				" | " +
				$t("app.adminPanel") +
				" | " +
				store.APP_NAME,
		},
		{ property: "og:description", content: store.META_DATA.NAME_DESCRIPTION },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

const form = reactive({
	post_date: "",
	title: "",
	meta_title: null as string | null,
	description: null as string | null,
	read_time: null as number | null,
	slug: "",
	image: null as File | null,
	image_alt: null as string | null,
	thumb: null as File | null,
	thumb_alt: null as string | null,
	categories: null as number[] | null,
	status: 1,
	blog_type: -1,
	content: "" as string | "",
	tags: null as number[] | null,
	sid: storeSession.userToken,
});

const isValidLoginForm = computed(() => {
	const hasFields = !!(
		form.title &&
		form.post_date &&
		form.slug &&
		form.status &&
		form.blog_type &&
		form.content
	);
	if (form.blog_type == -1) return false;
	if (hasFields && buttonActive.value) return true;
	return false;
});

// File change

function handleCoverFileChange(event: TFilePickerChange) {
	form.image = event.files ? event.files[0] : null;

	if (event.files && event.files?.length > 0) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			coverImagePreviewUrl.value = reader.result as string;
		});
		reader.readAsDataURL(event.files[0]);
	} else {
		coverImagePreviewUrl.value = "";
	}
}

function handleThumbFileChange(event: TFilePickerChange) {
	form.thumb = event.files ? event.files[0] : null;

	if (event.files && event.files?.length > 0) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			thumbImagePreviewUrl.value = reader.result as string;
		});
		reader.readAsDataURL(event.files[0]);
	} else {
		thumbImagePreviewUrl.value = "";
	}
}

// Categories and tags selection

function handleCategorySelection(
	categoryObj: ICategory,
	parentCategoryId?: number,
) {
	if (selectedCategories.value.includes(categoryObj.cat_id)) {
		selectedCategories.value = selectedCategories.value.filter(
			(id) => id !== categoryObj.cat_id,
		);

		if (
			categoryObj.child_categories &&
			categoryObj.child_categories.length > 0
		) {
			for (const child of categoryObj.child_categories) {
				if (selectedCategories.value.includes(child.cat_id)) {
					selectedCategories.value = selectedCategories.value.filter(
						(id) => id !== child.cat_id,
					);
				}
			}
		}
	} else {
		if (parentCategoryId) {
			if (selectedCategories.value.includes(parentCategoryId)) {
				selectedCategories.value.push(categoryObj.cat_id);
			} else {
				selectedCategories.value.push(parentCategoryId, categoryObj.cat_id);
			}
		} else {
			selectedCategories.value.push(categoryObj.cat_id);
		}
	}

	form.categories =
		selectedCategories.value.length === 0 ? null : selectedCategories.value;

	catBool.value = true;
}

// Create post

async function onClickCreatePost() {
	buttonActive.value = false;
	document.body.classList.add("cursor-wait");
	// slug check
	const slugBool = await store.slugCheck(form.slug, form.blog_type);
	if (!slugBool) {
		errorMsgText.value = $t("app.admin.errorMsg.slugExists");
		errorMsgBool.value = true;
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
		return false;
	}
	if (!store.slugRegex.test(form.slug)) {
		errorMsgText.value = $t("app.admin.errorMsg.slug");
		errorMsgBool.value = true;
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
		return false;
	}
	// content check
	if (!store.contentRegex.test(form.content)) {
		errorMsgText.value = $t("app.admin.errorMsg.content");
		errorMsgBool.value = true;
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
		return false;
	}

	if (form.read_time && form.read_time < 0) {
		form.read_time = null;
	}

	if (!form.image) {
		form.image_alt = null;
	}
	if (!form.thumb) {
		form.thumb_alt = null;
	}

	const formData = new FormData();
	for (const key in form) {
		if (
			key !== "image" &&
			key !== "thumb" &&
			key !== "categories" &&
			key !== "tags" &&
			key !== "read_time" &&
			key !== "content" &&
			key !== "post_date"
		) {
			formData.append(key, (form as Record<string, any>)[key]);
		}
	}
	if (form.image) {
		formData.append("image", form.image);
	}

	if (form.thumb) {
		formData.append("thumb", form.thumb);
	}

	if (catBool.value && form.categories && form.categories.length > 0) {
		for (const categoryId of (form as Record<string, any>).categories) {
			formData.append("categories", categoryId);
		}
	}

	if (selectedTags.value && selectedTags.value.length > 0) {
		const tagsIds = extractIdsFromArray(selectedTags.value);

		for (const tagId of tagsIds) {
			formData.append("tags", String(tagId));
		}
	}

	if (form.content) {
		const characterCount = form.content.length;
		const readTime = Math.ceil(characterCount / 5 / 200);

		formData.append("content", form.content);
		formData.append("read_time", String(readTime));
	}

	if (form.post_date) {
		formData.append("post_date", store.formatDatepickerDate(form.post_date));
	}

	try {
		const res = await api.postsAdd(formData);
		toast.openToastSuccess($t("app.admin.createPostSuccess"));
		resetForm();
		console.warn(res.data);
	} catch (err: any) {
		errorMsgBool.value = true;
		errorMsgText.value = $t("app.errorMessage");
		console.warn(err.message);
	} finally {
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
	}
}

function extractIdsFromArray(arr: ITag[]): number[] {
	return arr.map((item: ITag) => item.tag_id);
}

function resetForm() {
	form.post_date = "";
	form.title = "";
	form.meta_title = null;
	form.description = null;
	form.read_time = null;
	form.slug = "";
	form.image = null;
	form.image_alt = null;
	form.thumb = null;
	form.thumb_alt = null;
	form.categories = null;
	form.status = 1;
	form.blog_type = -1;
	form.content = "";
	form.tags = null;

	selectedCategories.value = [];
	selectedTags.value = [];
	catBool.value = false;
	errorMsgBool.value = false;

	const quillEditor = document.querySelector(".ql-editor");
	if (quillEditor) {
		quillEditor.innerHTML = "";
	}
}

watch(errorMsgBool, (newValue, oldValue) => {
	if (newValue) {
		errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
		errorMsgTimeout.value = setTimeout(() => {
			errorMsgBool.value = false;
		}, 5000);
	} else {
		errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
	}
});

function applyContentFromDataValue(baseFontSize = 20) {
  const sizeElements = document.querySelectorAll('.ql-size .ql-picker-item, .ql-size .ql-picker-label');
  let styleContent = `.ql-size {\n`;

  for (const element of sizeElements) {
    const dataValue = element.dataset.value;
    if (dataValue) {
      const valueInPx = parseFloat(dataValue) * baseFontSize;
      const displayValue = dataValue === "1em" ? "Normal" : `${valueInPx}px`;
      styleContent += `
        [data-value="${dataValue}"]::before {
          content: "${displayValue}" !important;
        }
      `;
    }
  }

  styleContent += `}\n`;

  const style = document.createElement('style');
  style.textContent = styleContent;
  document.head.append(style);
}

onMounted(async () => {
	categoriesList.value = await store.getSsCategories();
	tagsList.value = await store.getTags();

	setTimeout(() => {
		void nextTick(() => {
				// const Font = Quill.import("attributors/class/font");
				// 	Font.whitelist = ['mirza', 'roboto']; 
				// 	Quill.register(Font, true);

				const Size = Quill.import('attributors/style/size');
				Size.whitelist = ['1em', '0.4em', '0.45em', '0.5em', '0.55em', '0.6em', '0.7em', '0.9em', '1.2em', '1.5em', '1.8em', '2.4em', '3em', '3.6em'];
				Quill.register(Size, true);
				applyContentFromDataValue(20);
		});
	}, 300);

});


</script>

<template lang="pug">
.admin-main-wrapper
	.admin-title {{ $t("app.admin.createPostTitle") }}
	.admin-items-wrapper
		.group
			label {{ $t("app.admin.postData.title") }}*
				span &nbsp;({{ form.title?.length }}/255)
			input(v-model="form.title", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.metaTitle") }}
				span &nbsp;({{ form.meta_title ? form.meta_title.length : 0 }}/255)
			input(v-model="form.meta_title", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.description") }}*
			input(v-model="form.description", type="textbox")
		.group
			label {{ $t("app.admin.postData.postDate") }}*
			modern-date-picker(v-model="form.post_date")
		.group
			label {{ $t("app.admin.postData.slug") }}*
				span &nbsp;({{ form.slug?.length }}/255)
			input(v-model="form.slug", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.coverImage") }}
			.admin-image-choose-wrapper
				file-picker(
					title="Choose Cover Image",
					placeholder="Select an image file",
					:multiple=false,
					@change="handleCoverFileChange"
				)
					template(#buttonLabel)
						span.admin-file-picker-button-title {{ $t("app.admin.chooseImage") }}
				.admin-image-preview-wrapper(
					v-if="form.image",
					:style="{ 'background-image': 'url(' + coverImagePreviewUrl + ')' }"
				)
				.admin-image-preview-no-image-wrapper(
					v-else,
					:style="{ 'background-image': 'url(' + noImage + ')' }"
				)
		.group
			label {{ $t("app.admin.postData.coverAlt") }}
				span &nbsp;({{ form.image_alt ? form.image_alt.length : 0 }}/255)
			input(v-model="form.image_alt", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.thumbImage") }}
			.admin-image-choose-wrapper
				file-picker(
					title="Choose Thumb Image",
					placeholder="Select an image file",
					:multiple=false,
					@change="handleThumbFileChange"
				)
					template(#buttonLabel)
						span.admin-file-picker-button-title {{ $t("app.admin.chooseImage") }}
				.admin-thumb-preview-wrapper(
					v-if="form.thumb",
					:style="{ 'background-image': 'url(' + thumbImagePreviewUrl + ')' }"
				)
				.admin-thumb-preview-no-image-wrapper(
					v-else,
					:style="{ 'background-image': 'url(' + noImage + ')' }"
				)
		.group
			label {{ $t("app.admin.postData.thumbAlt") }}
				span &nbsp;({{ form.thumb_alt ? form.thumb_alt.length : 0 }}/255)
			input(v-model="form.thumb_alt", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.categories") }}
			div
				.categories-wrapper(
					v-for="category in categoriesList",
					:key="category.cat_id"
				)
					input(
						:id="category.cat_name",
						style="display: none",
						type="checkbox",
						:value="category.cat_id",
						:checked="selectedCategories.includes(category.cat_id)",
						@change="handleCategorySelection(category)"
					)
					label.category-label(:for="category.cat_name") 
						span(
							:class="{ 'checkbox-selected': selectedCategories.includes(category.cat_id) }"
						) {{ category.cat_name }}
					template(
						v-for="subCategory in category.child_categories",
						:key="subCategory.cat_id"
					)
						input(
							:id="subCategory.cat_name",
							style="display: none",
							type="checkbox",
							:value="subCategory.cat_id",
							:checked="selectedCategories.includes(subCategory.cat_id)",
							@change="handleCategorySelection(subCategory, category.cat_id)"
						)
						label.sub-category-label(:for="subCategory.cat_name")
							span(
								:class="{ 'checkbox-selected': selectedCategories.includes(subCategory.cat_id) }"
							) {{ subCategory.cat_name }}
		.group
			label {{ $t("app.admin.postData.status") }}*
			select(v-model.number="form.status")
				option(
					v-for="(value, index) in store.POST_STATUSES",
					:key="index",
					:value="index",
					:selected="index === 1"
				) {{ value }}
		.group
			label {{ $t("app.admin.postData.blogType") }}*
			select(v-model.number="form.blog_type")
				option(value=-1, disabled) {{ $t("app.admin.postForm.selectBlogType") }}
				option(:value="store.REGULAR_BLOG.BLOG_TYPE_ID") {{ store.REGULAR_BLOG.BLOG_TYPE_NAME }}
				option(:value="store.LEGAL_BLOG.BLOG_TYPE_ID") {{ store.LEGAL_BLOG.BLOG_TYPE_NAME }}
		.group
			label {{ $t("app.admin.postData.tags") }}
				multiselect-form(
					v-model="selectedTags",
					searchable,
					mode="tags",
					:options="tagsList || []",
					label="tag_name",
					value-prop="tag_id",
					:placeholder="store.placeholders.tagsSearch"
				)
		.group
			label {{ $t("app.admin.postData.content") }}*
				.admin-form-quill-editor-wrapper
					quill-editor.quill-editor(
						v-model:content="form.content",
						contentType="html",
						theme="snow",
						:options="store.toolbarOptions",
						:placeholder="store.placeholders.content"
					)
					//- #quill-editor
		.admin-submit
			button(:disabled="!isValidLoginForm", @click="onClickCreatePost")
				span {{ $t("app.submit") }}
			div(v-if="errorMsgBool") {{ errorMsgText }}
</template>

<style>
.ql-editor{
	font-size: 18px;
}
@media screen and (width >= 1024px) {
	.ql-editor{
		font-size: 20px;
	}
}
</style>

<style lang="scss" scoped>
$selected-item: $background-sidebar-gray;
// Categories start

.categories-wrapper {
	overflow: visible;
	display: flex;
	flex-wrap: wrap;
}

.category-label,
.sub-category-label {
	cursor: pointer;

	span {
		border-radius: 4px;
		padding: 4px 6px;
		border: 1px solid $form-input-border-light;

		&:hover {
			background-color: $selected-item;
		}
	}
}

.category-label {
	display: block;
	width: 100%;
	margin: 10px 0;
}

.sub-category-label {
	font-style: italic;
	color: rgb(102, 102, 102);
	margin-bottom: 8px;
	margin-left: 20px;
}

.parentPostRequiredParams {
	color: rgb(102, 102, 102);
}

// Categories end

input[type="file"] {
	margin-top: 4px;
}

input[type="textbox"] {
	padding: 8px 16px;

	&::placeholder {
		color: rgb(153, 153, 153);
	}
}

select,
input[type="text"],
input[type="date"],
input[type="number"],
input[type="checkbox"],
input[type="textbox"] {
	width: 100%;
	padding: 8px;
	border: 1px solid $form-input-border-light;
	border-radius: 4px;
	box-sizing: border-box;
	font-size: 16px;

	&:focus {
		border: 1px solid $form-input-border-light;
		outline: none;
	}
}
.checkbox-selected {
	background-color: $selected-item;
}

.red-text {
	color: rgb(255, 0, 0) !important;
}

// ******* Date picker start

.modern-date-picker {
	:deep() {
		.dp__main {
			.dp__input {
				width: 100%;
				padding: 8px;
				border: 1px solid $form-input-border-light;
				border-radius: 4px;
				box-sizing: border-box;
				font-size: 16px;
				padding-left: var(--dp-input-icon-padding);
			}
		}
	}
}

// Date picker end

// Multiselect form start

.multiselect-form {
	width: 100%;
	border: 1px solid $form-input-border-light;
	border-radius: 4px;
	box-sizing: border-box;

	:deep() {
		.is-disabled {
			cursor: not-allowed;
		}
		.multiselect-wrapper {
			height: 100%;
			width: 100%;
			margin: 0;
			padding: 8px;

			.multiselect-tags {
				height: 100%;
				width: 100%;
				margin: 0;
				padding: 0;

				.multiselect-tags-search {
					height: 100%;
					width: 100%;
					font-size: 16px;
					padding: 0;
				}

				.multiselect-tag{
					background-color: rgb(204, 204, 204);
					color: unset;
					font-weight: unset;
				}
			}
		}
	}
}
</style>
