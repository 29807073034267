<script setup lang="ts">
import { useDefaultStore } from "@/store";

const store = useDefaultStore();

definePage({
	name: "LegalBlogPage",
	redirect: "/pravna/blog",
});
</script>

<template lang="pug">
.blog-main-wrapper
	.blog-main-content-wrapper
		router-view(v-slot="{ Component }")
			//- transition(name="fade", mode="out-in")
			component(:is="Component")
</template>

<style lang="scss" scoped></style>
