<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";
import { useToastStore } from "@/store/toast";

definePage({
	name: "ContactPage",
	meta: {
		name: "Kontakt",
	},
});

const store = useDefaultStore();
const toast = useToastStore();
const route = useRoute();
const name = ref("");
const email = ref("");
const phone_number = ref("");
const message = ref("");
const terms = ref(false);

useHead({
	title: `${$t("app.contact.title")} | ${store.APP_NAME}`,
	meta: [
		{
			name: "title",
			content: `${$t("app.contact.title")} | ${store.APP_NAME}`,
		},
		{
			name: "description",
			content: $t("app.contact.metaDesc"),
		},
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		// { name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: `${$t("app.contact.title")} | ${store.APP_NAME}`,
		},
		{
			property: "og:description",
			content: $t("app.contact.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

// Send email

async function sendMail() {
	if (!terms.value) {
		toast.openToastError($t("app.legalContact.errorMsg.terms"));
		return false;
	}

	if (!isValidMailForm.value) {
		toast.openToastError($t("app.legalContact.errorMsg.required"));
		return false;
	}
	const params = {
		name: name.value,
		email: email.value,
		phone_number: phone_number.value,
		message: message.value,
	};
	try {
		const res = await api.contactUs(params);
		console.warn(res.data.res);
		toast.openToastSuccess($t("app.legalContact.success"));
		name.value = "";
		email.value = "";
		phone_number.value = "";
		message.value = "";
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

const isValidMailForm = computed(() => {
	const hasFields = !!(name.value && email.value);
	if (hasFields) return true;
	return false;
});
</script>

<template>
	<div class="contact-wrapper">
		<div class="contact-content-wrapper">
			<div class="contact-content-left">
				<h1 class="contact-content-left-main-title">
					{{ $t("app.contact.title") }}
				</h1>
				<div class="contact-content-left-title">
					{{ $t("app.legalContact.contactUs") }}
				</div>
				<div class="footer-bar-top-center">
					<a
						class="footer-bar-top-center-item"
						href="https://www.facebook.com/suncicasolarshop/"
						rel="me nofollow"
						target="_blank"
						aria-label="Find us on social media"
					>
						<img
							src="@/assets/image/socials/contact-fb.svg"
							alt="Facebook"
							loading="lazy"
						/>
					</a>
					<a
						class="footer-bar-top-center-item"
						href="https://www.tiktok.com/@solarsrbija"
						rel="me nofollow"
						target="_blank"
						aria-label="Find us on social media"
					>
						<img
							src="@/assets/image/socials/contact-tt.svg"
							alt="TikTok"
							loading="lazy"
						/>
					</a>
					<a
						class="footer-bar-top-center-item"
						href="https://www.linkedin.com/company/suncica-solarni-paneli-i-solarni-sistemi/"
						rel="me nofollow"
						target="_blank"
						aria-label="Find us on social media"
					>
						<img
							src="@/assets/image/socials/contact-linkedin.svg"
							alt="LinkedIn"
							loading="lazy"
						/>
					</a>
					<a
						class="footer-bar-top-center-item"
						href="https://www.instagram.com/suncica_shop/"
						rel="me nofollow"
						target="_blank"
						aria-label="Find us on social media"
					>
						<img
							src="@/assets/image/socials/contact-ig.svg"
							alt="Instagram"
							loading="lazy"
						/>
					</a>
				</div>
				<div class="footer-bar-top-left">
					<span class="label"
						>{{ $t("app.companyInfo.nameLabel") }}:&nbsp;</span
					>
					<span>{{ $t("app.companyInfo.name") }}</span>
					<br />
					<span class="label">{{ $t("app.companyInfo.pibLabel") }}:&nbsp;</span>
					<span>{{ $t("app.companyInfo.pib") }}</span>
					<br />
					<span class="label">{{ $t("app.companyInfo.mbLabel") }}:&nbsp;</span>
					<span>{{ $t("app.companyInfo.mb") }}</span>
					<br />
					<span class="label"
						>{{ $t("app.companyInfo.addressLabel") }}:&nbsp;</span
					>
					<span>
						<a
							href="https://maps.app.goo.gl/gRdpsNX7pvt1TLbG8"
							rel="nofollow"
							aria-label="View location on map"
							target="_blank"
						>
							{{ $t("app.companyInfo.address") }}
						</a>
					</span>
					<br />
					<span class="label"
						>{{ $t("app.companyInfo.activityLabel") }}:&nbsp;</span
					>
					<span>{{ $t("app.companyInfo.activity") }}</span>
					<br />
					<span class="label"
						>{{ $t("app.companyInfo.emailLabel") }}:&nbsp;</span
					>
					<span>
						<a href="mailto:office@suncica.co.rs"> office@suncica.co.rs </a>
					</span>
					<br />
					<span class="label"
						>{{ $t("app.companyInfo.workingHoursLabel") }}:&nbsp;</span
					>
					<span>Ponedeljak-petak: 09:00-17:00h</span>
				</div>
				<div class="button-wrapper">
					<a
						class="home-page-full-button call-us-button"
						href="tel:+38163267266"
						aria-label="Call us"
					>
						<span id="post-button-text">{{ $t("app.callUs") }}</span>
						<span id="post-button-text">063/267-266</span>
					</a>
				</div>
			</div>
			<div class="contact-content-right">
				<div class="contact-content-right-container">
					<div class="contact-content-right-title">
						{{ $t("app.legalContact.send").toUpperCase() }}
					</div>
					<div class="contact-content-right-group">
						<label class="contact-label" for="name">
							{{ $t("app.legalContact.name") }}
							<span class="required-input">*</span>
						</label>
						<input id="name" v-model="name" class="contact-input" type="text" />
					</div>
					<div class="contact-content-right-group">
						<label class="contact-label" for="email">
							{{ $t("app.legalContact.email") }}
							<span class="required-input">*</span>
						</label>
						<input
							id="email"
							v-model="email"
							class="contact-input"
							type="text"
						/>
					</div>
					<div class="contact-content-right-group">
						<label class="contact-label" for="phone">
							{{ $t("app.legalContact.phone") }}
						</label>
						<input
							id="phone"
							v-model="phone_number"
							class="contact-input"
							type="text"
						/>
					</div>
					<div class="contact-content-right-group">
						<label class="contact-label" for="message">
							{{ $t("app.legalContact.message") }}
						</label>
						<textarea
							id="message"
							v-model="message"
							class="contact-input"
							type="text"
						></textarea>
					</div>
					<div class="contact-content-right-terms">
						<input id="terms" v-model="terms" type="checkbox" />
						<label for="terms" class="contact-content-right-terms-text">
							{{ $t("app.legalContact.termsText") }}
						</label>
					</div>
					<div class="button-wrapper">
						<div class="home-page-full-button" @click="sendMail">
							{{ $t("app.legalContact.buttonText") }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.contact-wrapper {
	.contact-cover {
		position: relative;
		aspect-ratio: 100 / 33;
		img {
			width: 100%;
		}
		.cover-content-wrapper {
			position: absolute;
			left: 0;
			bottom: 6%;
			color: $app-dark-color;
			margin-left: 24px;

			.main {
				line-height: 42px;
				font-weight: bold;
				font-size: 36px;
				color: transparent;
				-webkit-text-stroke: 1px $app-dark-color;
				// transform: translateX(-50px);
				// opacity: 0;
				// animation: slide-from-side 1s 0s forwards;
			}
		}
	}
	.contact-content-wrapper {
		padding: 24px;
		.contact-content-left {
			margin-bottom: 24px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			max-height: 671px;
			// transform: translateX(50px);
			// opacity: 0;
			// animation: slide-from-side-r 1s 0s forwards;
			.contact-content-left-title {
				font-size: 22px;
				font-weight: 700;
				line-height: 26px;
				text-align: center;
				padding-bottom: 24px;
				// transform: translateX(50px);
				// opacity: 0;
				// animation: slide-from-side-r 1s 0s forwards;
			}

			.contact-content-left-main-title {
				font-family: Pelinka, sans-serif;
				font-size: 18px;
				line-height: 24px;
				font-weight: 700;
				margin-bottom: 12px;
				text-transform: uppercase;
			}

			.button-wrapper {
				display: flex;
			}
			.footer-bar-top-center {
				color: $text-color-lighter;
				margin-bottom: 30px;
				display: flex;
				justify-content: center;
				align-items: center;

				.footer-bar-top-center-item {
					border-radius: 50%;
					border: 1px solid $app-accent-color1;
					height: 45px;
					width: 45px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					margin-right: 18px;

					&:hover {
						border: 1px solid rgb(241, 196, 124);
					}

					img {
						max-width: 23px;
						max-height: 23px;
					}
				}
			}

			.footer-bar-top-left {
				margin-bottom: 30px;
				span {
					font-family: "Galano Grotesque Alt", sans-serif;
					font-size: 16px;
					font-weight: 200;
					line-height: 24px;
					color: $app-dark-color;
					user-select: all;

					&.label {
						font-weight: 600;
					}

					a {
						text-decoration: none;

						&:hover {
							opacity: 0.8;
						}
					}
				}
			}
		}

		.contact-content-right {
			display: flex;
			justify-content: center;
			margin-top: 50px;
			// opacity: 0;
			// animation: appear-from-bot 1s 0s forwards;
		}
	}
}

@media screen and (width >= 600px) {
	.contact-wrapper {
		.contact-content-wrapper {
			.contact-content-left {
				.contact-content-left-text {
					margin-right: 24px;
				}
				.contact-content-left-contact-info-wrapper {
					display: flex;
					justify-content: space-between;
					.contact-content-left-contact-info-email-wrapper,
					.contact-content-left-contact-info-phone-wrapper {
						width: 49%;
					}
				}
			}
		}
	}
}

@media screen and (width >= 760px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				bottom: 30%;

				.main {
					line-height: 84px;
					font-size: 80px;
					-webkit-text-stroke: 2px $app-dark-color;
				}
			}
		}
		.contact-content-wrapper {
			.contact-content-left {
				.contact-content-left-title {
					font-size: 24px;
					line-height: 30px;
					padding: 24px 0 50px;
					// transform: translateX(-50px);
					// opacity: 0;
					// animation: slide-from-side 1s 0s forwards;
				}

				.contact-content-left-main-title {
					font-size: 30px;
					line-height: 38px;
				}

				.footer-bar-top-left {
					margin-bottom: 50px;
					span {
						font-size: 18px;
						line-height: 28px;
					}
				}

				.footer-bar-top-center {
					margin-bottom: 50px;
				}
			}

			.contact-content-right {
				display: flex;
				justify-content: center;
			}
		}
	}
}

@media screen and (width >= 1024px) {
	.contact-wrapper {
		.contact-content-wrapper {
			.contact-content-left {
				.contact-content-left-main-title {
					font-size: 40px;
					line-height: 46px;
					margin-bottom: 50px;
				}
			}
		}
	}
}

@media screen and (width >= 1350px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				margin-left: 100px;
				bottom: 30%;

				.main {
					line-height: 108px;
					font-size: 100px;
					-webkit-text-stroke: 3px $app-dark-color;
				}
			}
		}
		.contact-content-wrapper {
			display: flex;
			padding: 100px;
			.contact-content-left {
				width: 60%;
				max-width: 880px;
				margin-bottom: 0;
				padding: 48px 0 12px;
				.contact-content-left-title {
					font-size: 30px;
					line-height: 34.5px;
					padding: 0;
					text-align: left;
					margin-bottom: 50px;
				}

				.footer-bar-top-center {
					justify-content: left;
				}

				.footer-bar-top-left {
					width: 70%;
					margin-right: 2%;
					margin-bottom: 50px;
					span {
						font-size: 20px;
						line-height: 25px;
					}
				}
			}

			.contact-content-right {
				width: 40%;
				padding: 24px;
			}
		}
	}
}

@media screen and (width >= 1572px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				margin-left: 100px;
				bottom: 40%;

				.main {
					line-height: 160px;
					font-size: 150px;
					-webkit-text-stroke: 3px $app-dark-color;
				}
			}
		}
	}
}
</style>
