import { axios } from "./config.js";
import type {
	TAxiosGenRes,
	TBasicType,
	TLoginParam,
	TGetPosts,
	TGetPostsByTags,
	TGetTags,
	TEditCategories,
	TAddCategories,
	TRemoveCategories,
	TGetPostsByCategories,
	TGetCategories,
	TRemoveTags,
	TEditTags,
	TAddTags,
	TContactUsParam,
	TCalculatorResults,
	TChangePassword,
} from "./types";

export default {
	contactUs: (params: TContactUsParam) => axios.post("auth/contact-us", params),
	calculatorResults: (params: TCalculatorResults) =>
		axios.post("auth/calculator-results", params),
	authLogout: (sid: string) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/logout", { sid }),
	authVerify: (sid: string) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/verify", { sid }),
	authForgot: (sid: string) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/forgot-password", { sid }),
	// Posts
	postsGet: (params: TGetPosts) =>
		axios.get<TAxiosGenRes<TBasicType>>("posts/posts-get", { params }),
	postsGetById: (params: any) =>
		axios.get<TAxiosGenRes<TBasicType>>(
			`posts/posts-get/${params.pst_id}`,
			params,
		),
	postsAdd: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("posts/posts-add", params),
	postsEdit: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("posts/posts-edit", params),
	postsGetByTags: (params: TGetPostsByTags) =>
		axios.post<TAxiosGenRes<TBasicType>>("posts/posts-get-tags", params),
	postsGetByCategories: (params: TGetPostsByCategories) =>
		axios.get<TAxiosGenRes<TBasicType>>("posts/posts-get-categories", {
			params,
		}),
	timelineGet: () =>
		axios.get<TAxiosGenRes<TBasicType>>("posts/posts-get-timeline"),
	// Categories
	categoriesGet: (params?: TGetCategories) =>
		axios.get<TAxiosGenRes<TBasicType>>("categories/categories-get", {
			params,
		}),
	categoriesRemove: (params: TRemoveCategories) =>
		axios.post<TAxiosGenRes<TBasicType>>(
			"categories/categories-remove",
			params,
		),
	categoriesAdd: (params: TAddCategories) =>
		axios.post<TAxiosGenRes<TBasicType>>("categories/categories-add", params),
	categoriesEdit: (params: TEditCategories) =>
		axios.post<TAxiosGenRes<TBasicType>>("categories/categories-edit", params),
	// Auth Blog
	authLogin: (params: TLoginParam) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/login", params),
	authRegister: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/signup", params),
	authRenewSession: (sid: string) =>
		axios.get<TAxiosGenRes<TBasicType>>("auth/check-session", {
			params: { sid },
		}),
	// Tags
	tagsGet: (params?: TGetTags) =>
		axios.get<TAxiosGenRes<TBasicType>>("tags/tags-get", { params }),
	tagsRemove: (params: TRemoveTags) =>
		axios.post<TAxiosGenRes<TBasicType>>("tags/tags-remove", params),
	tagsEdit: (params: TEditTags) =>
		axios.post<TAxiosGenRes<TBasicType>>("tags/tags-edit", params),
	tagsAdd: (params: TAddTags) =>
		axios.post<TAxiosGenRes<TBasicType>>("tags/tags-add", params),
	// users
	changePassword: (params: TChangePassword) =>
		axios.post<TAxiosGenRes<TBasicType>>("users/users-edit", params),
};
