<script setup lang="ts">
definePage({
	name: "HomePage",
	redirect: "/pravna-lica",
	meta: {
		name: "",
	},
});
</script>

<template lang="pug">
div ...
</template>

<style lang="scss" scoped></style>
