import api from "@/api";
// import { jwtDecode } from "jwt-decode";
import {
	getLocalStorageReac,
	getSessionStorageReac,
	setLocalStorageReac,
	setSessionStorageReac,
} from "@/assets/js/helpers";
// import { useToastStore } from "./toast";
// import { baseURL } from "@/api/config";
// import { useDefaultStore } from "@/store";
import type { TUserData } from "@/api/types.ts";

export const useSessionStore = defineStore("session", () => {
	// const store = useDefaultStore();
	const router = useRouter();
	const userToken = ref("");
	const userData = ref<TUserData | null>(null);
	const tokenRefreshTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
	const isBusyRenewalToken = ref(false);
	const userPermissions = ref<
		Record<string, boolean> | Record<string, unknown>
	>({});
	const isLoggedIn = computed(() => !!userToken.value);

	//

	// const userImage = computed(() => {
	// 	return getImageUrl(userData.value?.img_id);
	// });
	// function getImageUrl(imageId?: number | string): string | null {
	// 	if (imageId) {
	// 		// return `${baseURL}/users/images-get/${imageId}`;
	// 		//
	// 		return `${store.BASE_URL}/users/images-get/${imageId}`;
	// 	}
	// 	return null;
	// }

	function saveToken(token: string, isRemember = false, usrData?: TUserData) {
		userToken.value = token;
		if (usrData) userData.value = usrData ?? null; //

		if (token) {
			if (isRemember) {
				setLocalStorageReac("user-token", token);
			} else {
				setSessionStorageReac("user-token", token);
			}
		} else {
			const sesToken = getSessionStorageReac("user-token");
			const locToken = getLocalStorageReac("user-token");
			sesToken.value = null;
			locToken.value = null;
		}
	}

	async function loadSessionToken() {
		const sesToken =
			(getSessionStorageReac("user-token").value as string) || "";
		const locToken = (getLocalStorageReac("user-token").value as string) || "";

		// eslint-disable-next-line unicorn/consistent-function-scoping
		const loadSession = async (token: string) => {
			if (typeof token === "string") {
				saveToken(token);
				if (!tokenRefreshTimeout.value) {
					await setupTokenRenewal(true, true, token); //
				}
			} else {
				console.error("Invalid token format", token);
			}
		};

		if (sesToken) {
			await loadSession(sesToken);
		} else if (locToken) {
			await loadSession(locToken);
		} else {
			setTimeout(
				() => {
					void loadSessionToken();
				},
				1000 * 60 * 10,
			);
		}
	}

	async function setupTokenRenewal(
		isRefreshToken = false,
		debug = true,
		token: string = "",
	) {
		if (isBusyRenewalToken.value) {
			// console.warn(":: Busy token, exiting");
			return;
		}

		isBusyRenewalToken.value = true;
		tokenRefreshTimeout.value && clearTimeout(tokenRefreshTimeout.value);
		tokenRefreshTimeout.value = null;

		if (isRefreshToken) {
			await renewToken(token); //
		}

		const TIME_TO_REFRESH = 10 * 60 * 1000; //
		if (debug) {
			console.log(
				`:: Setup token refresh - ${TIME_TO_REFRESH / 60 / 1000}min`,
				isRefreshToken,
			);
		}
		tokenRefreshTimeout.value = setTimeout(() => {
			void setupTokenRenewal(true, true, token); //
		}, TIME_TO_REFRESH);
		isBusyRenewalToken.value = false;
	}

	function cancelRenewToken() {
		if (tokenRefreshTimeout.value) {
			clearTimeout(tokenRefreshTimeout.value);
			tokenRefreshTimeout.value = null;
		}
	}

	// ******
	//
	// async function renewToken() {
	// 	if (!userToken.value && tokenRefreshTimeout.value) {
	// 		return;
	// 	}

	// 	try {
	// 		const res = await api.authRenewSession();
	// 		const token = res.data.data.data;
	// 		console.log(":: Token refreshed");
	// 		saveToken(token);
	// 	} catch {
	// 		// ignored
	// 	}
	// }

	/**
	 *
	 *
	 *
	 *
	 *
	 * {
	 *		"user":{
	 *			"usr_id": 2,
	 *			"usr_displayname": "Admin 222",
	 *			"usr_email": "admin@example.com",
	 *			"ust_id": 1
	 *		},
	 *		"sid": "4DgVv1tqQ5WKuBzwwltgJ1YspMyY1aPn",
	 *		"permissions":{
	 *			"create-post": true,
	 *			"change-post-status": true,
	 *		}
	 *	}
	 *
	 */

	async function renewToken(tkn: string) {
		if (!userToken.value && tokenRefreshTimeout.value) {
			return;
		}

		try {
			const res = await api.authRenewSession(tkn); //
			const token = res.data.sid;
			console.log(":: token refreshed");
			saveToken(token, false, res.data.user);
		} catch {
			userToken.value = "";
		}
	}

	async function setupLogin(
		payload: Parameters<typeof api.authLogin>[0],
		isRemember = false,
	) {
		try {
			const res = await api.authLogin(payload);
			userPermissions.value = res.data.permissions;
			saveToken(res.data.sid, isRemember, res.data.user);
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}
	async function logoutApi(
		token: string,
		showMsg = false,
		shouldRedirect = true,
	): Promise<boolean> {
		const checkRedirectUser = () => {
			if (shouldRedirect) {
				void router.replace("/");
			}
		};

		if (!userToken.value) {
			console.warn(":: User already logged out");
			// useToastStore().openToastWarning("User already logged out");
			checkRedirectUser();
			return false;
		}
		try {
			await api.authLogout(token); //
			cancelRenewToken();

			if (showMsg) {
				// useToastStore().openToastInfo("User logged out");
				// privremeno resenje
				alert("User logged out");
			}

			checkRedirectUser();

			return true;
		} catch {
			return false;
		} finally {
			saveToken("", false);
		}
	}

	/**
	 * This function is used to check if the current user has specific permission
	 *
	 * userPermissions.value = {
	 * 'create-user': true,
	 * 'delete-user': false
	 * }
	 *
	 */
	function can(...keys: string[]) {
		return keys.every((key) => userPermissions.value[key]);
	}

	return {
		isLoggedIn,
		userData,
		// userImage,
		can,
		setupLogin,
		saveToken,
		loadSessionToken,
		setupTokenRenewal,
		logoutApi,
		// getImageUrl,
		userToken,
	};
});
