<script setup lang="ts">
import { getLocalStorageReac } from "./assets/js/helpers";
import { type Ref } from "vue";
import { useDefaultStore } from "./store";
import { useSessionStore } from "./store/session";
import { useModalStore } from "./store/modal";
import ModernModal from "./components/shared/modal/ModernModal.vue";

const store = useDefaultStore();
const storeSession = useSessionStore();
const modalStore = useModalStore();
const route = useRoute();
const appBaseTitle = import.meta.env.VITE_APP_APP_NAME;

const modernModalRef = ref<null | InstanceType<typeof ModernModal>>(null);
// const appMetaTitle = computed(() => {
// 	const name = route.meta.name as string;
// 	if (name) {
// 		return `${appBaseTitle} - ${(name || "")?.toString()}`;
// 	}
// 	return appBaseTitle;
// });

function checkSetAppDarkTheme() {
	const settingReac = getLocalStorageReac(
		"app-color-scheme",
		false,
		"auto",
	) as Ref<any>;
	watch(
		settingReac,
		(isSetting: string) => {
			const prefersDark = window.matchMedia(
				"(prefers-color-scheme: dark)",
			).matches;
			if (isSetting === "dark" || (prefersDark && isSetting !== "light")) {
				document.documentElement.classList.add("dark");
			} else {
				document.documentElement.classList.remove("dark");
			}
		},
		{ immediate: true },
	);
}

watch(
	() => store.isMobileView,
	(val) => {
		if (val) document.body.classList.add("is-mobile");
		else if (!val) document.body.classList.remove("is-mobile");
	},
);

const canonicalLink = computed(() => {
	const baseUrl = window.location.origin;
	return baseUrl + route.path;
});

function onCloseModalComp() {
	if (modernModalRef.value) {
		modernModalRef.value.onClickClose(null);
	} else {
		console.error("No modal instance");
	}
}

function addScript() {
	const existingScript = document.querySelector(
		'script[type="application/ld+json"]',
	);
	if (!existingScript) {
		const script = document.createElement("script");
		script.type = "application/ld+json";
		script.innerHTML = JSON.stringify({
			"@context": "http://schema.org",
			"@type": "Organization",
			name: "Sunčica Solar",
			Description:
				"Zaposleni u Sunčica Solar su započeli posao kao sanjari u svetu energije, s vizijom da preoblikujemo kako Srbija vidi i koristi sunčevu svetlost. Naša misija je pretvoriti sunčeve zrake u vašu finansijsku nezavisnost i omogućiti vam da smanjite troškove električne energije.",
			url: "https://www.suncica.co.rs/",
			logo: "https://www.suncica.co.rs/assets/logo-wfmVWTcm.svg",
			contactPoint: {
				"@type": "ContactPoint",
				contactType: "Korisnički Servis",
				url: "https://www.suncica.co.rs/kontakt",
				mail: "office@suncica.co.rs",
			},
			sameAs: [
				"https://www.facebook.com/suncicasolarshop/",
				"https://www.tiktok.com/@solarsrbija",
				"https://www.linkedin.com/company/suncica-solarni-paneli-i-solarni-sistemi/",
				"https://www.instagram.com/suncica_shop/",
			],
		});
		document.head.append(script);
	}
}

function removeScript() {
	const existingScript = document.querySelector(
		'script[type="application/ld+json"]',
	);
	if (existingScript) {
		existingScript.remove();
	}
}

onMounted(async () => {
	await storeSession.loadSessionToken();
	await store.getCategories();
	const canonical: any = document.querySelector('link[rel="canonical"]');
	if (canonical) store.canonicalEl = canonical;
	if (route.path === "/pravna-lica") addScript();
});

onUnmounted(async () => {
	// eslint-disable-next-line no-storage/no-browser-storage
	localStorage.removeItem("homePageAcademyDataSuncica");
	// eslint-disable-next-line no-storage/no-browser-storage
	localStorage.removeItem("categoriesDataSuncica");
});

onBeforeMount(() => {
	checkSetAppDarkTheme();
});

watch(
	() => route.path,
	(newValue, OldValue) => {
		if (store.canonicalEl) {
			const href = store.canonicalEl.href;
			const baseURL = new URL(href).origin;
			store.canonicalEl.setAttribute("href", baseURL + route.path);
		}
		if (
			newValue === "/pravna-lica" ||
			newValue === "/" ||
			newValue === "/pravna-lica/"
		) {
			addScript();
		} else {
			removeScript();
		}
	},
);
</script>

<template lang="pug">
//- teleport(to="head")
//- 	link(rel="canonical", :href="canonicalLink")
router-view(:class="{ 'is-mobile': store.isMobileToggled }")
enon-browser-detect
modern-modal(
	v-if="modalStore.isGlobalModalVisible",
	:key="modalStore.getModalData('comp')",
	ref="modernModalRef",
	:config="modalStore.getModalData('config', {})",
	:form-style="modalStore.getModalData('formStyle', {})",
	@close-modal="modalStore.isGlobalModalVisible = null"
)
	template(#content="{ slotProps }")
		component(
			:is="modalStore.getModalData('comp')",
			v-bind="slotProps",
			@close-modal="onCloseModalComp"
		)
</template>

<style lang="scss">
@import "./assets/css/base.css";

body {
	display: flex;
	justify-content: center;

	&.is-mobile {
		overflow-y: hidden;
	}

	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 12px;
		height: 5px;

		&:hover {
			cursor: pointer;
		}
	}
	&::-webkit-scrollbar-thumb {
		background-color: $app-dark-color;
		border: 1px solid $scrollbar-color;

		&:hover {
			cursor: pointer;
		}
	}
}

#app {
	width: 100vw;
	max-width: 1920px;

	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color-thumb;
		border-radius: 5px;
	}
}
</style>
