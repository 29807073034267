import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./i18n";
import { createPinia } from "pinia";
import InlineSvg from "vue-inline-svg";
import Toast from "vue-toastification";
import { createHead } from "@unhead/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "vue-toastification/dist/index.css";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(i18n);
app.component("InlineSvg", InlineSvg);
app.component("QuillEditor", QuillEditor);

const options = {
	position: "bottom-right",
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: false,
	icon: false,
	rtl: false,
	toastClassName: "my-custom-toast-class",
};
app.use(Toast, options);

const head = createHead();
app.use(head);

app.mount("#app");
