<script setup lang="ts">
import type { ICategory } from "src/components/sideBar/CategoriesBar.vue";

definePage({
	name: "PostPage",
});

export interface IPost {
	pst_id: number;
	pst_locale: string;
	// pst_id_root: number | null;
	pst_description: string | null;
	pst_meta_title: string | null;
	pst_post_date: string;
	pst_read_time: number;
	// pst_author_name: string;
	pst_author_id: number;
	pst_slug: string;
	pst_content: string;
	pst_created: string;
	blt_id: number;
	pst_title: string;
	img_id: number | null;
	// img_name: string | null;
	img_alt: string | null;
	img_image: any;
	tmb_id: number | null;
	// tmb_name: string | null;
	tmb_alt: string | null;
	tmb_image: any;
	sta_id: number;
	sta_name: string;
	// rating: number | null;
	// comments_count: number;
	tags: string;
	categories: ICategory[];
}

export interface ITag {
	tag_id: number;
	tag_name: string;
}
</script>

<template lang="pug">
post-comp(:blogType="'regular'")
</template>

<style lang="scss" scoped>
.tags-wrapper {
	height: 100px;
	padding-top: 20px;
}

.post-wrapper {
	display: flex;
}
</style>
