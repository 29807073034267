<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const store = useDefaultStore();
const route = useRoute();

definePage({
	name: "RegularPage",
});

useHead({
	title: $t("app.regular.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.regular.pageTitle") },
		{ name: "description", content: $t("app.regular.metaDesc") },
		// { name: "keywords", content: store.META_DATA.NAME_KEYWORDS },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.regular.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.regular.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

function hideCard() {
	store.activeCardLegal = 0;
	store.activeCardRegular = 0;
}
</script>

<template lang="pug">
div(@click="hideCard")
	regular-top
	regular-investment
	how-it-works
	installation-process
	top-section 
	get-started
	section-profit
	section-warranty
	section-insurance
	regular-financing
	regular-testimonials
	map-section(:blogType="'regular'")
	academy-section-new(:blogType="'regular'")
	partners-section-new
</template>

<style lang="scss" scoped></style>
