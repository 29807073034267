<script setup lang="ts">
import { debounce } from "@/assets/js/helpers";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import type { IPost } from "src/pages/index/blog/[slug]/index.vue";
import router from "@/router";
import { $t } from "@/i18n";

definePage({
	name: "EditPosts",
});

useHead({
	title: $t("app.admin.editPosts") + " | " + $t("app.adminPanel"),
});

const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
const searchPostText = ref("");
const postsList = ref<IPost[] | null | string>(null);
const blog_type = ref(2);
const orderByBool = ref(true);
const draftBool = ref(false);
const deletedBool = ref(false);
const publishedBool = ref(true);
const showMoreBool = ref(false);
const showLessBool = ref(false);
const currentPage = ref(1);
const limit = ref(11);

useHead({
	title:
		$t("app.admin.editPosts") +
		" | " +
		$t("app.adminPanel") +
		" | " +
		store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.admin.editPosts") },
		{ name: "description", content: store.META_DATA.NAME_DESCRIPTION },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS_NO_INDEX },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content:
				$t("app.admin.editPosts") +
				" | " +
				$t("app.adminPanel") +
				" | " +
				store.APP_NAME,
		},
		{ property: "og:description", content: store.META_DATA.NAME_DESCRIPTION },
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

// Get posts

async function getPosts() {
	document.body.classList.add("cursor-wait");
	postsList.value = await store.getPosts({
		search: searchPostText.value,
		blog_type: blog_type.value,
		latest: orderByBool.value,
		with_published: publishedBool.value,
		with_draft: draftBool.value,
		with_deleted: deletedBool.value,
		sid: storeSession.userToken,
		limit: limit.value,
	});

	if (postsList.value) {
		const n = currentPage.value * 10;
		if (postsList.value.length > n) {
			const p = postsList.value.length % n;
			if (p > 0) {
				showMoreBool.value = true;
				postsList.value = postsList.value.slice(0, n);
			}
		} else {
			showMoreBool.value = false;
		}
	}

	showLessBool.value = currentPage.value > 1;
	document.body.classList.remove("cursor-wait");
}

async function showMore(type: string) {
	if (type == "less") {
		limit.value -= 10;
		currentPage.value -= 1;
	} else if (type == "more") {
		limit.value += 10;
		currentPage.value += 1;
	}

	await getPosts();
}

const processChange = debounce(getPosts, 500, false);

async function changeOrder(ordBy: string) {
	orderByBool.value = ordBy === "latest";
	await getPosts();
}

async function changeStatus(status: string) {
	if (status == "published") {
		draftBool.value = false;
		deletedBool.value = false;
		publishedBool.value = true;
	} else if (status == "draft") {
		draftBool.value = true;
		deletedBool.value = false;
		publishedBool.value = false;
	} else if (status == "deleted") {
		draftBool.value = false;
		deletedBool.value = true;
		publishedBool.value = false;
	}
	await getPosts();
}

async function changeBlogType(type: string) {
	currentPage.value = 1;
	limit.value = 11;
	if (type == "regular") {
		blog_type.value = 2;
	} else if (type == "legal") {
		blog_type.value = 1;
	}
	await getPosts();
}

// edit post status

async function editPosts(pst_id: number, type: number) {
	const params = {
		pst_id,
		sta_id: type,
		sid: storeSession.userToken,
	};
	const res = await store.editPosts(params);
	if (res) {
		if (type === 3) {
			toast.openToastSuccess($t("app.admin.deletePostSuccess"));
		} else if (type === 1) {
			toast.openToastSuccess($t("app.admin.backToDraftSuccess"));
		}
	} else {
		toast.openToastError($t("app.errorMessage"));
	}
	await getPosts();
}

// navigate to edit post

async function navigateToEditPost(pst_id: number) {
	await router.push({
		path: "/admin/uredi-postove/" + pst_id,
	});
}

watch(searchPostText, async (newValue, oldValue) => {
	limit.value = 11;
	currentPage.value = 1;
	await getPosts();
});

onMounted(async () => {
	await getPosts();
});
</script>

<template lang="pug">
.edit-posts-wrapper
	.edit-post-order-bar
		.edit-post-order-bar-container-left
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': orderByBool }",
				@click="changeOrder('latest')"
			) {{ $t("app.admin.filters.latest") }}
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': !orderByBool }",
				@click="changeOrder('oldest')"
			) {{ $t("app.admin.filters.oldest") }}
		.edit-post-order-bar-container-center
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': publishedBool }",
				@click="changeStatus('published')"
			) {{ $t("app.admin.filters.published") }}
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': draftBool }",
				@click="changeStatus('draft')"
			) {{ $t("app.admin.filters.draft") }}
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': deletedBool }",
				@click="changeStatus('deleted')"
			) {{ $t("app.admin.filters.deleted") }}
		.edit-post-order-bar-container-right
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': blog_type == 2 }",
				@click="changeBlogType('regular')"
			) {{ $t("app.blogTypeRegular") }}
			span.edit-post-order-bar-item(
				:class="{ 'edit-post-order-bar-item-selected': blog_type == 1 }",
				@click="changeBlogType('legal')"
			) {{ $t("app.blogTypeLegal") }}
	.admin-edit-seacrh-wrapper
		input.admin-input(
			v-model="searchPostText",
			type="text",
			:placeholder="store.placeholders.postSearch",
			@input="processChange"
		)
	.edit-posts-posts-container
		template(v-if="postsList === null")
			p {{ $t("app.loading") }}
		template(v-else-if="postsList === 'wrong params'")
			p {{ $t("app.errorMessage") }}
		template(v-else-if="Array.isArray(postsList) && postsList.length === 0")
			p {{ $t("app.blog.emptyBlogPage") }}
		template(v-else-if="Array.isArray(postsList) && postsList.length !== 0")
			.admin-edit-item-container(v-for="post in postsList", :key="post.pst_id")
				.admin-edit-item-title
					| {{ post.pst_title }}
				.admin-edit-item-buttons
					i-fa-pen-to-square.edit-posts-icon(
						v-if="!deletedBool",
						@click="navigateToEditPost(post.pst_id)"
					)
					i-fa-trash.edit-posts-icon(
						v-if="!deletedBool",
						@click="editPosts(post.pst_id, 3)"
					)
					i-fa-circle-check.edit-posts-icon(
						v-if="deletedBool",
						@click="editPosts(post.pst_id, 1)"
					)
			.admin-edit-posts-show-more
				span(v-if="showLessBool", @click="showMore('less')") {{ $t("app.showLess") }}
				span(v-if="showMoreBool", @click="showMore('more')") {{ $t("app.showMore") }}
</template>

<style lang="scss" scoped>
$background-activ: $text-color;
.edit-posts-wrapper {
	margin: 0 3%;
	.edit-post-order-bar {
		margin-bottom: 12px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.edit-post-order-bar-container-center,
		.edit-post-order-bar-container-right {
			margin-left: 16px;
			margin-top: 16px;
		}

		.edit-post-order-bar-item {
			cursor: pointer;
			margin-left: 2px;
			border-radius: 4px;
			padding: 4px 6px;
			border: 1px solid $form-input-border-light;

			&:hover {
				background-color: $background-activ;
				color: $text-color-lighter;
				border: 1px solid $background-activ;
			}
		}

		.edit-post-order-bar-item-selected {
			background-color: $background-activ;
			color: $text-color-lighter;
			border: 1px solid $background-activ;
		}
	}

	.edit-posts-posts-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;
		max-width: 600px;
		margin: 0 auto;

		div {
			font-weight: 600;
		}

		.admin-edit-posts-show-more {
			margin-top: 18px;
			width: 100%;
			text-align: right;
			color: $form-input-border-light;

			span {
				cursor: pointer;
				margin-left: 12px;

				&:hover {
					color: $text-color;
				}
			}
		}
	}
}

@media screen and (width >= 760px) {
	.edit-posts-wrapper {
		.edit-post-order-bar {
			flex-direction: row;

			.edit-post-order-bar-container-center,
			.edit-post-order-bar-container-right {
				margin-top: unset;
			}
		}
	}
}

.admin-edit-item-title {
	@include ellipsis;

	-webkit-line-clamp: 1;
}
</style>
