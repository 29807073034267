import _definePage_default_0 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/blog/index.vue?definePage&vue'
import _definePage_default_3 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/blog/[slug]/index.vue?definePage&vue'
import _definePage_default_4 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/blog.vue?definePage&vue'
import _definePage_default_5 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/index.vue?definePage&vue'
import _definePage_default_6 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/akademija.vue?definePage&vue'
import _definePage_default_7 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/kako-funkcionisu-solarni-paneli.vue?definePage&vue'
import _definePage_default_8 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/kalkulator.vue?definePage&vue'
import _definePage_default_9 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/proces-ugradnje-instalacije-solarnih-panela.vue?definePage&vue'
import _definePage_default_10 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/reference.vue?definePage&vue'
import _definePage_default_11 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/reference.[slug]/index.vue?definePage&vue'
import _definePage_default_12 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/strahovi-mitovi.vue?definePage&vue'
import _definePage_default_13 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/video-testimonials.vue?definePage&vue'
import _definePage_default_14 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/zasto-investirati-u-solarnu-elektranu.vue?definePage&vue'
import _definePage_default_15 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/kontakt.vue?definePage&vue'
import _definePage_default_16 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/o-nama.vue?definePage&vue'
import _definePage_default_17 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/politika-privatnosti.vue?definePage&vue'
import _definePage_default_18 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna/blog/index.vue?definePage&vue'
import _definePage_default_19 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna/blog/[slug]/index.vue?definePage&vue'
import _definePage_default_20 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna.vue?definePage&vue'
import _definePage_default_21 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/index.vue?definePage&vue'
import _definePage_default_22 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/akademija.vue?definePage&vue'
import _definePage_default_23 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/finansiranje.vue?definePage&vue'
import _definePage_default_24 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/reference.vue?definePage&vue'
import _definePage_default_25 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/reference.[slug]/index.vue?definePage&vue'
import _definePage_default_26 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/strucnost.vue?definePage&vue'
import _definePage_default_27 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/video-testimonials.vue?definePage&vue'
import _definePage_default_28 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/reklamacije.vue?definePage&vue'
import _definePage_default_29 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/uslovi-koriscenja.vue?definePage&vue'
import _definePage_default_30 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/[...path].vue?definePage&vue'
import _definePage_default_31 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/index.vue?definePage&vue'
import _definePage_default_32 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/novi-post.vue?definePage&vue'
import _definePage_default_33 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-kategorije.vue?definePage&vue'
import _definePage_default_34 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-postove.vue?definePage&vue'
import _definePage_default_35 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-postove.[id]/index.vue?definePage&vue'
import _definePage_default_36 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-tagove.vue?definePage&vue'
import _definePage_default_37 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin.vue?definePage&vue'
import _definePage_default_38 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_39 from '/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/auth.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'HomePage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/index.vue'),
        /* no children */
      },
  _definePage_default_0
  ),
  _mergeRouteRecord(
      {
        path: 'blog',
        name: 'BlogHomePage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/blog.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'BlogPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/blog/index.vue'),
            /* no children */
          },
  _definePage_default_2
  ),
          {
            path: ':slug',
            /* internal name: '//blog/[slug]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'PostPage',
                component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/blog/[slug]/index.vue'),
                /* no children */
              },
  _definePage_default_3
  )
            ],
          }
        ],
      },
  _definePage_default_4
  ),
      {
        path: 'fizicka-lica',
        /* internal name: '//fizicka-lica' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'RegularPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/index.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
  _mergeRouteRecord(
          {
            path: 'akademija',
            name: 'RegularAcademy',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/akademija.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
  _mergeRouteRecord(
          {
            path: 'kako-funkcionisu-solarni-paneli',
            name: 'HowItWorksPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/kako-funkcionisu-solarni-paneli.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'kalkulator',
            name: 'CalculatorPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/kalkulator.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'proces-ugradnje-instalacije-solarnih-panela',
            name: 'InstallationProcessPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/proces-ugradnje-instalacije-solarnih-panela.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: 'reference',
            name: 'RegularReferences',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/reference.vue'),
            /* no children */
          },
  _definePage_default_10
  ),
          {
            path: 'reference/:slug',
            /* internal name: '//fizicka-lica/reference.[slug]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'RegularReference',
                component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/reference.[slug]/index.vue'),
                /* no children */
              },
  _definePage_default_11
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'strahovi-mitovi',
            name: 'FearsMythsPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/strahovi-mitovi.vue'),
            /* no children */
          },
  _definePage_default_12
  ),
  _mergeRouteRecord(
          {
            path: 'video-testimonials',
            name: 'RegularTestimonials',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/video-testimonials.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
          {
            path: 'zasto-investirati-u-solarnu-elektranu',
            name: 'WhyInvestPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/fizicka-lica/zasto-investirati-u-solarnu-elektranu.vue'),
            /* no children */
          },
  _definePage_default_14
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'kontakt',
        name: 'ContactPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/kontakt.vue'),
        /* no children */
      },
  _definePage_default_15
  ),
  _mergeRouteRecord(
      {
        path: 'o-nama',
        name: 'AboutPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/o-nama.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'politika-privatnosti',
        name: 'PrivacyPolicyPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/politika-privatnosti.vue'),
        /* no children */
      },
  _definePage_default_17
  ),
  _mergeRouteRecord(
      {
        path: 'pravna',
        name: 'LegalBlogPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna.vue'),
        children: [
          {
            path: 'blog',
            /* internal name: '//pravna/blog' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'LegalBlogPage',
                component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna/blog/index.vue'),
                /* no children */
              },
  _definePage_default_18
  ),
              {
                path: ':slug',
                /* internal name: '//pravna/blog/[slug]' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'LegalPostPage',
                    component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna/blog/[slug]/index.vue'),
                    /* no children */
                  },
  _definePage_default_19
  )
                ],
              }
            ],
          }
        ],
      },
  _definePage_default_20
  ),
      {
        path: 'pravna-lica',
        /* internal name: '//pravna-lica' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'LegalPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/index.vue'),
            /* no children */
          },
  _definePage_default_21
  ),
  _mergeRouteRecord(
          {
            path: 'akademija',
            name: 'LegalAcademy',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/akademija.vue'),
            /* no children */
          },
  _definePage_default_22
  ),
  _mergeRouteRecord(
          {
            path: 'finansiranje',
            name: 'FinancingPage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/finansiranje.vue'),
            /* no children */
          },
  _definePage_default_23
  ),
  _mergeRouteRecord(
          {
            path: 'reference',
            name: 'LegalReferences',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/reference.vue'),
            /* no children */
          },
  _definePage_default_24
  ),
          {
            path: 'reference/:slug',
            /* internal name: '//pravna-lica/reference.[slug]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'LegalReference',
                component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/reference.[slug]/index.vue'),
                /* no children */
              },
  _definePage_default_25
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'strucnost',
            name: 'ExpertisePage',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/strucnost.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
  _mergeRouteRecord(
          {
            path: 'video-testimonials',
            name: 'LegalTestimonials',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/pravna-lica/video-testimonials.vue'),
            /* no children */
          },
  _definePage_default_27
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'reklamacije',
        name: 'ComplaintsPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/reklamacije.vue'),
        /* no children */
      },
  _definePage_default_28
  ),
  _mergeRouteRecord(
      {
        path: 'uslovi-koriscenja',
        name: 'TermsOfUsePage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/index/uslovi-koriscenja.vue'),
        /* no children */
      },
  _definePage_default_29
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'CatchAllPage',
    component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_30
  ),
  _mergeRouteRecord(
  {
    path: '/admin',
    name: 'AdminPage',
    component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'AdminPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/index.vue'),
        /* no children */
      },
  _definePage_default_31
  ),
  _mergeRouteRecord(
      {
        path: 'novi-post',
        name: 'CreatePost',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/novi-post.vue'),
        /* no children */
      },
  _definePage_default_32
  ),
  _mergeRouteRecord(
      {
        path: 'uredi-kategorije',
        name: 'EditCategories',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-kategorije.vue'),
        /* no children */
      },
  _definePage_default_33
  ),
  _mergeRouteRecord(
      {
        path: 'uredi-postove',
        name: 'EditPosts',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-postove.vue'),
        /* no children */
      },
  _definePage_default_34
  ),
      {
        path: 'uredi-postove/:id',
        /* internal name: '/admin/uredi-postove.[id]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'EditPost',
            component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-postove.[id]/index.vue'),
            /* no children */
          },
  _definePage_default_35
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'uredi-tagove',
        name: 'EditTags',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/admin/uredi-tagove.vue'),
        /* no children */
      },
  _definePage_default_36
  )
    ],
  },
  _definePage_default_37
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: 'AuthPage',
    component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/auth.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'LoginPage',
        component: () => import('/home/enon/Desktop/projekti/sncc/suncica-web/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_38
  )
    ],
  },
  _definePage_default_39
  )
]
