<script setup lang="ts">
definePage({
	name: "AdminPage",
	redirect: "/admin/novi-post",
});
</script>

<template lang="pug">
div ...
</template>

<style lang="scss" scoped>
.admin-wrapper {
	display: flex;
}
</style>
