<script setup lang="ts">
import { useDefaultStore } from "@/store";

const route = useRoute();
const store = useDefaultStore();

definePage({
	name: "FearsMythsPage",
	redirect: "/fizicka-lica/zasto-investirati-u-solarnu-elektranu",
});
</script>

<template lang="pug">
.page
	.title Strahovi i mitovi
	.content
		span.lorem Strahovi i mitovi
</template>

<style lang="scss" scoped>
.page {
	padding: 20px;
	height: 100%;
	width: 100%;
}
</style>
