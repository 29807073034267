<script setup lang="ts">
import router from "@/router";

const route = useRoute();

definePage({
	name: "CatchAllPage",
});

onMounted(() => {
	setTimeout(() => {
		void router.replace("/");
	}, 5000);
});
</script>

<template lang="pug">
header-bar-top
div
	h2 Page not found
	span You will be redirected in 5 seconds
</template>

<style lang="scss" scoped>
div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	font-family: "Galano Grotesque", sans-serif;
}
</style>
